import React from "react"
import useTranslations from "../utils/useTranslations"
import FeaturesList from "../components/featuresList"
import ComparisonPanels from "../components/comparisonPanels"

import laptopClassic from "../images/homepage/ui-classic.svg"
import laptopWeb from "../images/homepage/ui-web.svg"
import androidImg from "../images/homepage/ui-android.svg"

import LocalizedLink from "../components/localizedLink"
import Button from "../components/button"
import { CompatibilityContext } from "../components/compatibilityContext";
import Logo from "../components/logo"
import backgrounds from "../components/backgrounds"
import LifeStyleLarge from "../images/homepage/life-style-large.png"
import UtLiteLogo from "../images/homepage/ut-lite-beta-logo.svg"
import UtLightning from "../images/homepage/ut-lightning.svg"

import gwIcon from "../images/promotions/goldenwallet/gw-icon.svg"
import { ANDROID_LINK_FREE, getAndroidLink } from "../utils/getAndroidLink";
import Link from "gatsby-link";
import { getCountry } from "../utils/country"

class Index extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      isMac: false,
      isCatalina:false,
      displayMac:"d-none",
      isAndroid: false,
      androidLinkFree: ANDROID_LINK_FREE,
      isCrOS: false,
      isiOS: false,
      isIpad: false
    };
    this.handleWebClick = this.handleWebClick.bind(this)
    this.handleClassicClick = this.handleClassicClick.bind(this)
  }


  componentDidMount(){

    this.setState({
      isMac: window.gatsbyIsMac,
      isCatalina: window.gatsbyIsCatalina,
      displayMac: window.gatsbyIsMac ? "" : "d-none",
      isAndroid: window.gatsbyIsAndroid,
      isCrOS: window.gatsbyIsCrOS,
      isIpad: window.gatsbyIsIpad,
      isiOS: window.gatsbyIsiOS,
      androidLinkFree: getAndroidLink('free'),
      countryCode: getCountry(),
    })
  }

  handleClassicClick(e, link){
    this.context(e, 'downloading', "Classic", ["Win", "Osx"], link )

  }

  handleWebClick(e){
    this.context(e, 'downloading', "Web", ["Win", "Osx"])
  }

  render(){
    const { text, pageName } = this.props.pageContext
    const t = useTranslations(text)

    // let classicLaptop = (this.state.isMac) ? classicMacImg : classicImg;
    // let classicLaptopWebp = (this.state.isMac) ? classicMacImgWebp : classicImgWebp;
    // let classicLaptop2x = (this.state.isMac) ? classicMacImg2x : classicImg2x;
    // let classicLaptopWebp2x = (this.state.isMac) ? classicMacImgWebp2x : classicImgWebp2x;
    
    const locale = this.props.pageContext.locale
    const googlePlayButton = `/images/google-play-badges/google-play-badge-${locale}.png`
    // const appStoreButton = `/images/app-store-badges/app-store-badge-${locale}.svg`

    return (
      <>

        
        {
          this.state.isAndroid &&
          <div className="container-fluid px-0 text-black d-md-none" style={backgrounds.lightBg}>

            <div className="container pt-4 py-md-5">

              <div className="row pb-5">
                <div className="col-lg-5 order-2 order-lg-2 text-center text-lg-left d-flex flex-column align-items-lg-start justify-content-center">
                  <Logo secondaryColor="black" tag="p" productName="Android" className=""/>
                  <div className="col-lg-6 d-flex justify-content-center overflow-hidden" style={{height: '540px'}}>
                    <img src={androidImg} alt="Download µTorrent Android" className="d-block align-self-center" width="540" height="540"></img>
                  </div>
                  <a href={this.state.androidLinkFree} id="homepage-android-gp-logo-hero" className="index-mobile-google-play" target="_blank" rel="noopener noreferrer">
                    
                    <img className="google-play-button img-fluid mr-3" src={googlePlayButton} style={{ height: `200`, width: `77` }} alt="µTorrent for Android Google Play Store badge" ></img>
                    
                  </a>
                </div>
              </div>

            </div>
          </div>
        }

        <div className="top-section-container py-5 py-lg-0 background-noise-dark sal-animate" style={backgrounds.darkBg2}>

          <div className="container py-2">

            <div className="row text-center text-white mt-4 mt-sm-0 overflow-hidden">
              <div className="col-lg-6 order-lg-2 d-flex flex-column justify-content-center">
                <Logo secondaryColor="white" tag="h2" productName="Web" className="mb-3" fsMax={26}/>
                {/*<img src={laptopWeb} alt="Download µTorrent Web" className="img-fluid d-none d-md-block hero-img"></img>*/}  
                {/*<object type="image/svg+xml" data={laptopWeb}><img src={laptopWeb} alt="Download µTorrent Web" className="img-fluid d-block d-md-none mb-4"></img></object>*/}

                <h2 className="text-size-24 text-spacing-1 font-weight-normal">{t(`Play torrents while you download.`)}</h2>
                <h2 className="text-size-24 text-spacing-1 font-weight-normal mb-2">{t(`Ideal for new users.`)}</h2>
                {
                  !this.state.isMac &&
                    <Button onClick={this.handleWebClick} id="win-homepage-hero-utweb-comp" href="/web/compare/" className= "btn-primary text-spacing-1 mt-0 mt-md-3 mb-3 button-filled text-spacing-1 btn-dark-bg">{t(`Free Download`)}</Button>
                }
                <Button onClick={this.handleWebClick} id="mac-homepage-hero-utweb-comp" href="/web/compare/" className= {`${this.state.displayMac} btn-primary text-spacing-1 mt-0 mt-md-3 mb-2 button-filled text-spacing-1 btn-dark-bg`}>{t(`Free Download`)}</Button>
              </div>
              <div className="col-lg-6 mb-5 mb-lg-0 order-lg-1 d-none d-lg-flex justify-content-center" style={{height: '330px'}}>
                <img src={laptopWeb} alt="Download µTorrent Web" className="d-none d-lg-block" width="382" height="390"></img>
                {/*<object alt="Download µTorrent Web" className="d-none d-md-block" style={{ height: `400px`, width: `500px` }} type="image/svg+xml" data={laptopWeb}><img src={laptopWeb} alt="Download µTorrent Web" className="hero-img img-fluid d-none d-md-block mb-4"></img>Download µTorrent Web</object>*/}

              </div>
            </div>
          </div>
        </div>

        <div className="py-5 pt-lg-0 position-relative overflow-hidden">
         
            <div className="container">
              <div className="row text-center ">
                <div className="col-lg-6 order-lg-1 d-flex flex-column justify-content-center">
                  <Logo secondaryColor="black" tag="h2" productName="Classic" className="mb-4" fsMax={26}/>

                  <h1 className="text-size-24 text-spacing-1 font-weight-normal mb-3 mx-auto" style={{maxWidth: '375px', lineHeight: '1.5'}}>{t(`A very tiny desktop torrent client for experienced users.`)}</h1>
                  {
                    !this.state.isMac &&
                    <Button 
                      id="win-homepage-hero-classic-comp"
                      href="/desktop/compare/" 
                      onClick={this.handleClassicClick} 
                      className="btn-primary text-spacing-1 mt-0 mt-md-3 mb-3 button-filled text-spacing-1">
                        {t(`Free Download`)}
                    </Button>
                  }
                    <Button 
                      id="mac-homepage-hero-classic-dl"
                      href="/downloads/complete/track/stable/os/mac/" 
                      onClick={(e)=>{this.handleClassicClick(e, '/downloads/complete/track/stable/os/mac/')}} 
                      className= {`${this.state.displayMac} btn-primary text-spacing-1 mt-0 mt-md-3 mb-2 button-filled text-spacing-1`}>
                        {t(`Free Download`)}
                    </Button>
                </div>
                <div className="col-lg-6 order-lg-2 d-none d-lg-flex justify-content-center align-items-center" style={{height: '330px'}}>
                  <img src={laptopClassic} alt="Download µTorrent Classic" className="d-none d-lg-block" width="351" height="390"></img>

                </div>
              </div>
            </div>
            <div className="container z-2 mt-5 mt-lg-0">

              <p className="text-center font-weight-bold feature-list-title mb-3">{t(`FeaturesHeader`)}</p>
              <p className="text-center mb-5 text-size-24">{t(`µTorrent Classic Torrent Client Features.`)}</p>
              <div className = "index-features-list m-auto ">
                <FeaturesList text={text} isMac={this.state.isMac}/>
              </div>
            </div>


        </div>
        {<>{this.getUtLiteModule()}</>}
          {
            !this.state.isMac &&
              <div className="position-relative overflow-hidden panel-bg">
                <div className="z-2 pt-5">
                  <p className="text-center font-weight-bold feature-list-title mb-3">{t(`Premium`)}</p>
                  <p className="text-center mb-5 text-size-24">{t(`Go Pro for Added Security and No Distractions.`)}</p>

                  <ComparisonPanels pageContext={{ "text": text, "pageName":pageName, "isMac":this.state.isMac }} >
                    <p className="w-100 text-center mt-5">{t(`Learn more about our premium products:`)}  <span className='d-block d-sm-inline-block'><LocalizedLink id="win-homepage-utweb-premium" to="/web/pro/">µTorrent Web</LocalizedLink> | <LocalizedLink id="win-homepage-classic-premium" to="/pro/">µTorrent Classic</LocalizedLink></span> </p>
                    <p className="w-100 text-center mb-5 pb-4">{t(`*Available in µTorrent Classic only.`)}</p>
                  </ComparisonPanels>
                </div>
              </div>
          }

          <div className={`container-fluid px-0 text-black ${this.state.isAndroid ? 'd-none d-md-block' : ''}`} style={backgrounds.lightBg}>
            
            <div className="container mb-md-5 pt-5">
              <p className="text-center font-weight-bold feature-list-title mb-3 pt-md-5">{t(`Mobile`)}</p>
              <h3 className="text-center mb-5 text-size-24 font-weight-normal">{t(`Learn more About our Mobile Torrent Application.`)}</h3>
            </div>

            <div className="container pt-4 py-md-5">

              <div className="row pb-5">
                <div className="col-lg-5 order-2 order-lg-2 text-center text-lg-left d-flex flex-column align-items-lg-start justify-content-center">
                  <Logo secondaryColor="black" tag="h2" productName="Android" className="mb-4"/>
                  <p className="index-mobile-description text-center text-lg-left">
                    {t(`Get the #1 torrent downloader on Google Play with over 100 million downloads. µTorrent Android helps you download torrent files or magnet links from your Android smartphone or tablet. Enjoy a simplified torrent download experience with no speed or size limits!`)}
                  </p>

                  <ul className="live-list text-left align-self-center align-self-lg-start mb-3 my-0">
                    <li>
                      {t(`Download torrents and magnet links`)}
                    </li>
                    <li>
                      {t(`Choose your file download location`)}
                    </li>
                    <li>
                      {t(`Fast, light and powerful`)}
                    </li>

                  </ul>
                  <a href={this.state.androidLinkFree} id="homepage-android-gp-logo" className="index-mobile-google-play" target="_blank" rel="noopener noreferrer">
                    
                    <img className="google-play-button img-fluid mr-3" src={googlePlayButton} style={{ height: `200`, width: `77` }} alt="µTorrent for Android Google Play Store badge" ></img>
                    
                  </a>
                </div>
                <div className="col-lg-6 d-none d-lg-flex justify-content-center" style={{height: '600px'}}>
                  <img src={androidImg} alt="Download µTorrent Android" className="d-none d-lg-block align-self-center" width="540" height="540"></img>
                </div>
              </div>

            </div>
          </div>
      </>
    )

  }

  getUtLiteModule(){
    const { text } = this.props.pageContext
    const t = useTranslations(text)
    return (<div className="top-section-container  background-noise-dark sal-animate text-center overflow-hidden order-1" style={backgrounds.darkBg3}>
            <div className="position-absolute m-auto  mt-n5" style={{"left":0,"right":0,zIndex:-100}}>
              <img className="utlite-lightning" style={{width:"100%", maxWidth:"400px"}} src={UtLightning}/>
            </div>
            <div className="d-flex flex-column flex-lg-row m-auto py-5  " style={{'maxWidth': '800px'}}>

              <div className="p-3 mb-2 utlite-info">


                <div className="text-white mx-auto mx-lg-0 mb-3 rounded-pill border border-white text-size-14 px-3 py-1" style={{"width": "fit-content"}}>{t(`new`)}</div>
                <div className="mb-3"><img className="" src={UtLiteLogo}/></div>
                <div className="text-white text-size-16 lite-info-text mt-3" >{t(`Lite usability`)}</div>
                <Button id="homepage-lite-launch-desktop" href="https://lite.utorrent.com?utm_source=uts&utm_medium=carousel&utm_campaign=beta_promo&utm_id=1" className= {` btn-primary text-spacing-1 button-filled text-spacing-1 btn-dark-bg d-none d-lg-block my-4`}>{t(`launch`)}</Button>
                <div className="text-white text-size-14 d-none d-lg-block">{t(`Lite compatible`)}</div>
              </div>

              <div className="sharing-logo m-auto" >
                <img className="sharing-logo " src={LifeStyleLarge} alt="Sharing Life Style" width="382" height="390" ></img>
              </div>

              <div className="mx-auto d-flex mt-5 d-lg-none">
                <div className="m-auto">
                  <Button  id="homepage-lite-launch-mobile" href="https://lite.utorrent.com?utm_source=uts&utm_medium=carousel&utm_campaign=beta_promo&utm_id=1" className=" btn-primary button-filled btn-dark-bg ">{t(`launch`)}</Button>
                </div>
              </div>

            </div>
          </div> )
  }
}


Index.contextType = CompatibilityContext;

export default Index


